/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue"
import axios from "../../http/axios/index.js"
import state from "../state.js"
import stateType from "@/store/type/moduleTypeState.js"
import stateProduct from "@/store/product/moduleProductState.js"
import mainState from '@/store/state.js'
export default {
  SET_ALL_TYPE_EXAM_LIST(state, payload) {
    state.allTypeExam = payload
  },
  SET_STAGING_EXAM_LIST(state, payload) {
    payload.sort((a, b) => b.id - a.id);  // 기본 내림
    state.stagingExamList = payload
  },
  SET_STAGING_EXAM_INFO(state, payload) {
    state.stagingExamInfo = payload
  },
  SET_STAGING_EXAM_ID(state, payload) {
    state.stagingExamId = payload
  },
  SET_FIND_STAGING_QUESTION_LIST(state, payload) {
    if(mainState.switchState == 0) {
      payload.sort((a, b) => b.question_id - a.question_id);  // 기본 내림
    }else {
      payload.sort((a, b) => a.question_id - b.question_id);  // 오름
    }
    state.findStagingQuestionList = payload
  },
  SET_STAGING_QUESTION_LIST(state, payload) {
    state.stagingQuestionList = payload
  },

  /* staging_exam에 쓰이는 Category_list */
  SET_LARGE_CATEGORY(state, payload) {
    state.largeCategory = payload
  },
  SET_MIDDLE_CATEGORY(state, payload) {
    state.middleCategory = payload
  },
  SET_SMALL_CATEGORY(state, payload) {
    state.smallCategory = payload
  },

  /* staging_exam에 쓰이는 content_list */
  SET_PUBLISHER_LIST(state, payload) {
    state.publisher = payload
  },
  SET_TEXTBOOK_SUBJECT_LIST(state, payload) {
    state.textbook_subject = payload
  },
  SET_TEXTBOOK_CONTENT_LIST(state, payload) {
    state.textbook_content = payload
  },
  SET_TEXTBOOK_CONTENT_MAIN_LIST(state, payload) {
    state.textbook_content_main = payload
  },
  SET_TEXTBOOK_CONTENT_SUB_LIST(state, payload) {
    state.textbook_content_sub = payload
  },

  SET_ALL_EXAM_SUBJECT_LIST(state, payload) {
    state.examSubjectAll = payload
  },
  SET_MUTATE_EXAM_LIST(state, payload) {
    state.mutateSubject = payload
  },
  SET_MUTATE_EXAM_ID(state, payload) {
    state.mutateId = payload
  },
  SET_MUTATE_EXAM_DETAIL(state, payload) {
    state.subjectDetail = payload
  },
  SET_MUTATE_DONE_LIST(state, payload) {
    state.reviewList = payload
  },
  SET_SEARCH_KEYWORD(state, payload) {
    state.keyword = payload
  },
  SET_SEARCH_EXAM_ALL(state, payload) {
    state.searchKeyword = payload
  },
  SET_ALLOWED_EXAM_LIST(state, payload) {
    state.allowExamList = payload
  },
  SET_USER_ALLOWED_EXAM_LIST(state, payload) {
    for (var i = 0; i < 3; i++) {
      var filter = payload.filter((item) => {
        return item.state == (Number(i) + 2)
      })
      if (i == 0) state.userAllowList = filter
      if (i == 1) state.userDoneList = filter
      if (i == 2) state.adminDoneList = filter
    }
  },
  SET_ALL_SCHOOL_LIST(state, payload) {
    state.allSchoolList = payload
  },
  SET_FILTERD_HIGHSCHOOL_LIST(state, payload) {
    state.filteredSchool = payload
  },
  SEARCH_SCHOOL(state, payload) {
    state.keyword = payload
  },
  SET_ACTIVE_REGION(state, payload) {
    state.activeRegion = payload
  },
  SET_ACTIVE_HIGHSCHOOL(state, payload) {
    state.activeHighSchool = payload
  },
  SET_SUBJECT_CODE_LIST(state, payload) {
    state.subjectCode = payload.sort((a, b) => {
      return a.subject_type_id - b.subject_type_id
    })
    // console.log(state.paymentProduct)
  },
  SET_SCHOOL_PUBLISHER(state, payload) {
    state.schoolPublisher = payload
  },
  SET_FILE_INFO(state, payload) {
    state.fileInfo = payload
  },
  SET_ACTIVE_GRADE_ID(state, payload) {
    state.gradeId = payload
  },
  SET_ACTIVE_EXAM(state, payload) {
    state.examId = payload
  },
  SET_ACTIVE_EXAM_ID(state, payload) {
    state.examId = payload
  },
  SET_ACTIVE_EXAM_INFO(state, payload) {
    state.activeExam = payload
  },
  SET_QUESTION_MULTIPLE(state, payload) {
    state.multipleList = payload
  },
  SET_EXAMPLE_MULTIPLE(state, payload) {
    state.multipleList.push(payload)
  },
  SET_ACTIVE_SUBJECT(state, payload) {
    state.activeSubject = payload
  },
  SET_EXAM_SUBJECT(state, payload) {
    state.examSubject = payload.sort((a, b) => {
      return a.subject_type_id - b.subject_type_id
    })
  },
  SET_EXAM_SUBJECT_FILE(state, payload) {
    state.subjectFile = payload
  },
  SET_SCHOOL_SUBJECT_ID(state, payload) {
    state.schoolId = payload
  },
  SET_SCHOOL_SUBJECT_DETAIL(state, payload) {
    state.subjectDetail = payload
  },
  SET_EXAM_SUBJECT_LIST(state, payload) {
    state.examSubjectAll = payload.filter((item) => {
      return item.state == 0
    })
    state.reviewList = payload.filter((item) => {
      return item.state == 1
    })
    console.log(payload)
  },
  SET_SCHOOL_SUBJECT_LIST(state, payload) {
    state.schoolSubjectAll = payload
  },
  SET_REVIEW_LIST(state, payload) {
    state.reviewList = payload
    // .sort((a, b) => {
    //   return a.subject_type_id - b.subject_type_id
    // })
  },
  DONE_EXAM_EDIT(state, payload) {
    state.examDone = payload
  },
  SKIP_EXAM_EDIT(state, payload) {
    state.skipExamEdit = payload
  },
  DONE_EXAM_ADMIN_CHECK(state, payload) {
    state.examAdminCheck = payload
  },
  SET_SUBJECT_DETAIL(state, payload) {
    state.subjectDetail = payload
  },
  SET_ACTIVE_FILE(state, payload) {
    state.activeFile = payload
  },
  SET_ACTIVE_QUESTION_LIST_ERROR_STATE(state, payload) {  // {id: int, state: bool}
    for(var i=0; i<state.questionList.length; i++) {
      if(state.questionList[i].id == payload.id) {
        state.questionList[i].divide_error_reported = payload.state 
      }
    }
  },
  SET_QUESTION_LIST(state, payload) {
    // if(state.activeExam.state == 3 && state.activeExam.admin_check == 3) {
    //   state.questionList = payload.filter((item)=> {
    //     return item.comment
    //   })
    // } else {
    state.questionList = payload
    // }
  },
  SET_HEAD_QUESTION_ID(state, payload) {
    Vue.set(state.questionList[state.activeQuestionIndex + payload], 'head_question_id', state.lastQuestion.id)
    // Vue.set(state.activeQuestion, 'head_question_id', state.lastQuestion.id)
  },
  DELETE_HEAD_QUESTION_ID(state, payload) {
    Vue.set(state.questionList[payload], 'head_question_id', null)
  },
  SET_ACTIVE_QUESTION(state, payload) {
    state.activeQuestion = payload
  },
  SET_LAST_QUESTION(state, payload) {
    state.lastQuestion = payload
  },
  SAVE_EDIT_INFO(state, payload) {
    state.activeQuestion = payload
  },
  SET_ACTIVE_QUESTION_INDEX(state, payload) {
    state.activeQuestionIndex = payload
  },
  CREATE_MULTIPLE(state, payload) {
    state.activeMultipleList.push(payload)
  },
  DELETE_MULTIPLE(state, payload) {
    const index = state.activeMultipleList.findIndex((item) => item.id == payload)
    if (index != -1) {
      state.activeMultipleList.splice(index, 1)
    }
  },
  SET_TARGET_USER(state, payload) {
    state.targetUser.push(payload)
  },
  CLEAR_TARGET_USER(state, payload) {
    state.targetUser = []
  },
  DELETE_TARGET_USER(state, payload) {
    Vue.delete(state.targetUser, payload)
  },
  SET_ACTIVE_YEAR(state, payload) {
    state.activeYear = payload
  },
  SET_ACTIVE_SEMESTER(state, payload) {
    state.activeSemester = payload
  },
  SET_ACTIVE_WORK_TYPE(state, payload) {
    state.activeWork = payload
  },
  SET_ACTIVE_SUBJECT_FILTER(state, payload) {
    state.activeFilterSub = payload
  },
  SET_ACTIVE_SELECT(state, payload) {
    state.activeSelect = payload
  },
  SET_ACTIVE_GRADE(state, payload) {
    state.activeGrade = payload
    // var index = state.activeGrade.findIndex((item) => {
    //   return item == payload
    // })
    // if(index != -1) {
    //   Vue.delete(state.activeGrade, index)
    // } else {
    //   Vue.set(state.activeGrade, state.activeGrade.length, payload)
    // }
  },
  SET_PROBLEM_LIST(state, payload) {
    state.problemList = payload
  },
  SET_EXAM_LIST_SCROLLTOP(state, payload) {
    state.scrollTop = payload
  },
  SET_EXAM_SORT_STATE(state, payload) {
    var index = state.examAllMenu.findIndex((item) => {
      return item.name == payload.name
    })
    var count = state.examAllMenu[index].arrow
    if (count < 2) {
      count++
    } else {
      count = 1
    }
    Vue.set(state.examAllMenu[index], 'arrow', count)
  },
  SET_ACTIVE_PRODUCT_CATEGORY(state, payload) {
    state.activeCategory = payload
    console.log(state.activeCategory)
  },
  SET_ACTIVE_PRODUCT_STATE(state, payload) {
    state.isProduct = payload
  },
  SET_QUESTION_MULTIPLE_LIST(state, payload) {
    state.questionMultiple = payload
  },
  SET_ACTIVE_CREATE_STATE(state, payload) {
    state.isCreate = payload
  },
  OPEN_CREATE_SCHOOL_EXAM(state, payload) {
    state.createModal = payload
  },
  OPEN_CREATE_NATIONAL_EXAM(state, payload) {
    state.createNational = payload
  },
  SET_ACTIVE_EXAM_TYPE(state, payload) {
    state.activeType = payload
  },
  SET_ACTIVE_CLASS_TYPE(state, payload) {
    state.activeClass = payload
  },
  SET_ACTIVE_ADMIN(state, payload) {
    state.activeAdmin = payload
  },
  SET_ALL_REVIEW_EXAM_VISIBILLE(state, payload) {
    state.showAllReview = payload
  },
  SET_LITERATURE_LIST(state, payload) {
    state.literatureList = payload
  },
  SET_ACTIVE_TAB_INDEX(state, payload) {
    state.activeIndex = payload
  },
  SET_ACTIVE_SCHOOL_CATEGORY(state, payload) {
    state.schoolCategory = payload
  },
  SET_OTHER_CATEGORY_LIST(state, payload) {
    state.otherCategory = payload
  },
  SET_ALL_SAMPLE_LIST(state, payload) {
    state.allSampleList = payload
  },
  SET_ACTIVE_BOOK_SUBJECT(state, payload) {
    state.subjectDetail = payload
  },
  SET_WARNING_MODAL(state, payload) {
    state.warningModal = payload;
  },
  SET_WARNING_QUESTION_INFO(state, payload) {
    console.log(payload);
    state.warningQuestionInfo.before_order_index = payload.before_order_index;
    state.warningQuestionInfo.now_order_index = payload.now_order_index;
    state.warningQuestionInfo.question_info = payload.question_info;
  },
  SET_NOT_ALL_DONE_MODAL(state, payload) {
    state.notAllDoneModal = payload;
  },

  // payload: {data: data_local, index: warningQuestionInfo[obj], question_list: this.$store.state.type.questionList, type: 1||2||3}
  SET_QUESTION_LIST_DETAIL_INFO(state, payload) {
    // if(payload.type == 1) {
    console.log('before_index 챌린지 문제 리스트 디테일 정보 question[index] 에 정보 넣기')
    console.log(payload);

    payload.question_list[payload.index].answer = payload.data.answer;
    payload.question_list[payload.index].answer_multiple = payload.data.answer_multiple;
    payload.question_list[payload.index].answer_number = payload.data.answer_number;
    payload.question_list[payload.index].category_id = payload.data.category_id;
    payload.question_list[payload.index].class_type = payload.data.class_type;
    // payload.question_list[payload.index].comment = payload.data.comment;  // default: 0 문제 유형에서는 사용안함
    payload.question_list[payload.index].head_question_id = payload.data.head_question_id;
    payload.question_list[payload.index].id = payload.data.id;
    payload.question_list[payload.index].question_index = payload.data.question_index;
    payload.question_list[payload.index].question_type = payload.data.question_type;

    payload.question_list[payload.index].level = payload.data.detail_level;


    var errorList = [];

    // 객관식일 경우 문제갯수가 없을 경우 errorList 추가
    if (payload.activeQuestion.question_list.length != 5 && payload.activeQuestion.question_type == 1) errorList.push({ 'name': '객관식문항' });

    // 난이도 기본값 선택(null) 추가되면서 errorList 추가
    if (payload.question_list[payload.index].level == null || payload.question_list[payload.index].level == 0) errorList.push({ 'name': '난이도' });
    if (payload.question_list[payload.index].class_type == 1) {

      // 영어는 문제유형 없음 || 문제유형이 없을 경우(payload.question_list[payload.index].category_id) null 
      if (payload.subject_code.substring(0, 3) != 'ENG' && payload.question_list[payload.index].category_id == null) errorList.push({ 'name': '문제유형' });
    } else if (payload.question_list[payload.index].class_type == 2) {
      // 정답이 없을 경우 (객관식, 주관식, 복수선택, 듣기평가)

      // 객관식, 듣기평가(payload.question_list[payload.index].answer_number) null\
      if (payload.question_list[payload.index].answer_number == null) {
        if (payload.question_list[payload.index].question_type == 1) errorList.push({ 'name': '객관식' });
        if (payload.question_list[payload.index].question_type == 4) errorList.push({ 'name': '듣기평가' });
      }

      // 주관식(payload.question_list[payload.index].answer) null
      if (payload.question_list[payload.index].answer == null && payload.question_list[payload.index].question_type == 2) errorList.push({ 'name': '주관식' });

      // 복수선택(payload.question_list[payload.index].answer_multiple)  [0]
      if (payload.question_list[payload.index].answer_multiple.length == 0 && payload.question_list[payload.index].question_type == 3) errorList.push({ 'name': '복수선택' });

    } else if (payload.question_list[payload.index].class_type == 3) {
      // 난이도 || 문제유형 || 정답 이 없을 경우

      // 영어는 문제유형 없음 || 문제유형이 없을 경우(payload.question_list[payload.index].category_id) null 
      if (payload.subject_code.substring(0, 3) != 'ENG' && payload.question_list[payload.index].category_id == null) errorList.push({ 'name': '문제유형' });

      // 객관식, 듣기평가(payload.question_list[payload.index].answer_number) null\
      if (payload.question_list[payload.index].answer_number == null) {
        if (payload.question_list[payload.index].question_type == 1) errorList.push({ 'name': '객관식' });
        if (payload.question_list[payload.index].question_type == 4) errorList.push({ 'name': '듣기평가' });
      }

      // 주관식(payload.question_list[payload.index].answer) null
      if (payload.question_list[payload.index].answer == null && payload.question_list[payload.index].question_type == 2) errorList.push({ 'name': '주관식' });

      // 복수선택(payload.question_list[payload.index].answer_multiple)  [0]
      if (payload.question_list[payload.index].answer_multiple.length == 0 && payload.question_list[payload.index].question_type == 3) errorList.push({ 'name': '복수선택' });
    }

    if (errorList.length) {
      console.log(errorList);
      state.questionDetailInfo.info = payload;
      state.questionDetailInfo.error_list = errorList;
      if (payload.type == 1 || payload.type == 2) state.warningModal = true;
    } else {
      payload.question_list[payload.index].state = true;
    }
    // }else {
    //   console.log(payload);
    // }
  },

  SET_DETAIL_INFO_CHECK_ERROR_LIST(state, payload) {  // 여기에 위에도 category_id 처럼 main_category_id, sub_category_id 추가하면 됌
    var errorList = [];

    // 객관식일 경우 문제갯수가 없을 경우 errorList 추가
    if (payload.activeQuestion.question_list.length != 5 && payload.activeQuestion.question_type == 1) errorList.push({ 'name': '객관식문항' });

    // 난이도 기본값 선택(null) 추가되면서 errorList 추가
    if (payload.question_list[payload.index].level == null || payload.question_list[payload.index].level == 0) errorList.push({ 'name': '난이도' });
    if (payload.question_list[payload.index].class_type == 1) {
      // 난이도는 기본값이 null이지만 null일 경우 하 로 자동 선택되있는 것 가틈(그래서 난이도는 null 이여도 무시할꺼(기본 세팅 되있음))
      // 난이도(payload.question_list[payload.index].detail_level) null 

      // 영어는 문제유형 없음 || 문제유형이 없을 경우(payload.question_list[payload.index].category_id) null 
      if (payload.subject_code.substring(0, 3) != 'ENG' && payload.question_list[payload.index].category_id == null) errorList.push({ 'name': '문제유형' });
    } else if (payload.question_list[payload.index].class_type == 2) {
      // 정답이 없을 경우 (객관식, 주관식, 복수선택, 듣기평가)

      // 객관식, 듣기평가(payload.question_list[payload.index].answer_number) null\
      if (payload.question_list[payload.index].answer_number == null) {
        if (payload.question_list[payload.index].question_type == 1) errorList.push({ 'name': '객관식' }); // 객관식 정답
        if (payload.question_list[payload.index].question_type == 4) errorList.push({ 'name': '듣기평가' });
      }

      // 주관식(payload.question_list[payload.index].answer) null
      if (payload.question_list[payload.index].answer == null && payload.question_list[payload.index].question_type == 2) errorList.push({ 'name': '주관식' });

      // 복수선택(payload.question_list[payload.index].answer_multiple)  [0]
      if (payload.question_list[payload.index].answer_multiple.length == 0 && payload.question_list[payload.index].question_type == 3) errorList.push({ 'name': '복수선택' });

    } else if (payload.question_list[payload.index].class_type == 3) {
      // 난이도 || 문제유형 || 정답 이 없을 경우

      // 영어는 문제유형 없음 || 문제유형이 없을 경우(payload.question_list[payload.index].category_id) null 
      if (payload.subject_code.substring(0, 3) != 'ENG' && payload.question_list[payload.index].category_id == null) errorList.push({ 'name': '문제유형' });

      // 객관식, 듣기평가(payload.question_list[payload.index].answer_number) null\
      if (payload.question_list[payload.index].answer_number == null) {
        if (payload.question_list[payload.index].question_type == 1) errorList.push({ 'name': '객관식' });
        if (payload.question_list[payload.index].question_type == 4) errorList.push({ 'name': '듣기평가' });
      }

      // 주관식(payload.question_list[payload.index].answer) null
      if (payload.question_list[payload.index].answer == null && payload.question_list[payload.index].question_type == 2) errorList.push({ 'name': '주관식' });

      // 복수선택(payload.question_list[payload.index].answer_multiple)  [0]
      if (payload.question_list[payload.index].answer_multiple.length == 0 && payload.question_list[payload.index].question_type == 3) errorList.push({ 'name': '복수선택' });
    }

    if (errorList.length) {
      console.log(errorList);
      state.questionDetailInfo.info = payload;
      state.questionDetailInfo.error_list = errorList;
      state.warningModal = true;
    }
  },

  SET_ERROR_EXAM_MODAL(state, payload) {
    state.errorExamModal = payload;
  },

  SET_SEARCH_TYPE(state, payload) {
    state.searchType = payload;
  },

  SET_QUESTION_LIST_DATA(state, payload) {  // payload: {index: int, data: boolean}
    state.questionList[payload.index].data = payload.data;
  },

  SET_QUESTION_LIST_CNT(state, payload) {   // payload: {index: int, question_count: int}
    state.questionList[payload.index].question_count = payload.question_count;
  },

  SET_ERROR_MULTIPLE(state, payload) {
    state.errorMultiple = payload;
  },

  RESET_FILTER_OPTION(state) {
    state.activeType = [];
    state.activeGrade = [];
    state.activeFilterSub = { name: '과목별', grade_id: '', subject_type_id: 0 };
    state.searchType = 2;
    state.activeYear = '연도별';
    state.searchKeyword = { key: "exam_subject", text: "" }
    state.selectExamGradeId = null;
    state.activeCategory = []; // 카테고리 필터
    state.hideSelected = []; // 상품 삭제 선택 리스트 필터는 아닌데 초기화 필요
    stateProduct.productSearch = '';  // 상품관리 검색값 초기화
    stateProduct.productHideSearch = '';  // 숨김 목록 검색값 초기화
  },

  SET_SELECT_EXAM_GRADE_ID(state, payload) {
    state.selectExamGradeId = payload;
  },

  SET_EXAM_IS_PRESS(state, payload) {
    state.examIsPress = payload;
  },

  SET_EXAM_PREV_POS_X(state, payload) {
    state.examPrevPosX = payload;
  },

  SET_EXAM_PREV_POS_Y(state, payload) {
    state.examPrevPosY = payload;
  },
  PUSH_HIDE_SELECTED(state, payload) {
    state.hideSelected.push(payload)
  },
  SPLICE_HIDE_SELECTED(state, payload) {
    state.hideSelected.splice(payload, 1);
  },
  RESET_HIDE_SELECTED(state) {
    state.hideSelected = [];
  },
  SET_HIDE_SELECTED(state, payload) {
    state.hideSelected = payload;
  },
  SET_QUESTION_DETAIL_INFO(state, payload) { // {error_lsit: array, info: object{data: object, index: int, question_list: array, type: index}}
    state.questionDetailInfo = payload;
  },
  SET_NATIONAL_EXAM_LIST(state, payload) {
    state.nationalExamList = payload;
  },
  SET_NATIONAL_MUTATE_EXAM_LIST(state, payload) {
    state.nationalMutateExamList = payload;
  },
  SET_CATEGORY_LIST(state, payload) {
    stateType.categoryList = payload.filter((item) => {
      if (item.grade_list.includes(state.activeExam.grade_id)) {
        if (state.activeExam.subject_detail_type_id) {
          if (state.activeExam.subject_type_id == 9) {
            return (item.subject_type_id == state.activeExam.subject_type_id && item.subject_detail_type_id == state.activeExam.subject_detail_type_id) || item.name == '교과과정 제외'
          } else {
            return (item.subject_type_id == state.activeExam.subject_type_id) || item.name == '교과과정 제외'
          }
        } else {
          return (item.subject_type_id == state.activeExam.subject_type_id) || item.name == '교과과정 제외'
        }
      }
    })
  },
  
  SET_EXAM_INFO(state, payload) {
    state.examInfo = payload;
  },
  
  SET_AI_USER_ALLOWED_EXAM_LIST(state, payload) {
    // for (var i = 0; i < 3; i++) {
    //   var filter = payload.filter((item) => {
    //     return item.state == 3 && (
    //       i == 0 ? (item.admin_check == 2)
    //       : i == 1 ? (item.admin_check == 3 || item.admin_check == 4)  // 2개임 
    //       : item.admin_check == 5
    //     ) || item.state == 4 && i == 1 && item.admin_check == 5
    //   })
    //   if (i == 0) state.userAllowList = filter  // 검토중인 시험   admin_check:2
    //   if (i == 1) state.userDoneList = filter   // 문제 신고 시험, 검토 완료 admin_check:4,5
    //   if (i == 2) state.adminDoneList = filter  // 승인 완료  admin_check: 5
    // }
    var userAllowList = []; // 검토 중인 시험 state: 3, admin_check: 2
    var userDoneList = [];  // 검토 완료 state: 3, admin_check: 4   문제 신고 시험 state: ?, admin_check: ?    
    var adminDoneList = []; // 승인 완료 state: 4, admin_check: 5
    console.log(payload);
    console.log('payload');
    for(var i=0; i<payload.length; i++) {
      if(payload[i].state == 3 && payload[i].admin_check == 2) {
        userAllowList.push(payload[i]);
      }
      if(payload[i].state == 3 && payload[i].admin_check == 4) {
        userDoneList.push(payload[i]);
      }
      if(payload[i].state == 4 && payload[i].admin_check == 5) {
        adminDoneList.push(payload[i]);
      }
    }
    state.userAllowList = userAllowList;
    state.userDoneList = userDoneList;
    state.adminDoneList = adminDoneList;
    console.log(state.userAllowList);
    console.log('state.userAllowList');
    console.log(state.userDoneList);
    console.log('state.userDoneList');
    console.log(state.adminDoneList);
    console.log('state.adminDoneList');
  },
  SET_USER_ALLOW_LIST(state, payload) {
    state.userAllowList = payload;
  },
  SET_USER_DONE_LIST(state, payload) {
    state.userDoneList = payload;
  },
  SET_AI_QUESTION_LIST_VISIT(state, payload) {  // payload: {visit_a: bool, visit_q: bool}  // visit_a: 해설, visit_q: 문제
    // console.log('mutations SET_AI_QUESTION_LIST_VISIT');
    // console.log(state.questionList);
    // console.log(state.activeQuestion);  //220286
    for(var i=0; i<state.questionList.length; i++) {
      if(state.questionList[i].id == state.activeQuestion.id) { // state.questionList[index].visit_a, "".visit_q : default값 0,    0: 미확인 1: 확인
        // state.questionList[i]
        if(payload.visit_a == true) {
          state.questionList[i].visit_a = 1;
        }
        if(payload.visit_q == true) {
          state.questionList[i].visit_q = 1;
        }
      }
    }
    // console.log(payload);
  },
  SET_ERROR_REPORTED_QUESTION_LIST(state, payload) {
    state.questionList[payload].divide_error_reported = 'false';
  },
  EXCLUDE_EXAM(state, payload) {
    var idx = state.exam.allTypeExam.findIndex(exam => exam.id == payload)
    if(idx != -1) {
      state.exam.allTypeExam[idx].excluded_state = 1;
    }
  }
}
